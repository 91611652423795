@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--background: #05386b;
	--primary: #379683;
	--secondary: #5cdb95;
	--tertiary: #8ee4af;
	--text: #ffffff;
	--errorText: #ff0000;
}

html {
	width: 100%;
	height: 100%;
	background-color: #05386b;
}
